
@import './variable';
@import '../../../public/assets/bootstrap/scss/bootstrap.scss';
@import './color';
@import './typography';

.navbar {
    border-bottom: 1px solid $navbarBottomBorder;
    font-family: AmericanSans-Regular;
    color: $NavbarTextColor;
}

.navbar-brand {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.navbar-light .navbar-nav .nav-link {
    color: $NavbarTextColor;
}

.timelineheader {
    color: #1B62C0;
    font-family: AmericanSans-Regular;
    font-weight: 800;
    font-size: 1.2rem;
}

@media (min-width: 992px) {
    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 1rem;
        padding-left: 1rem;
    }
}