.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: auto;
  // background: lime; 
  background: rgba(0,0,0, 0.9);/* Just to visualize the extent */
  z-index: 5;
}

.push__Wrapper-ypksxs-0 { 
  position: relative;
  top: 40%;
  left: 45%;
  width: 100%;
  text-align: center;
  margin-top: 30px;
}