#VerticallyCenteredModal {
    display: flex;
    justify-content: center;
    position: absolute !important;
    top: 40% !important;
    transform: translate(-50%, -50%) !important;
    .modal-header {
        font-size: 1.5rem;
        font-family: $font-family-AmericanSans-Bold;
    }
    .modal-header .close{
        font-size: 2rem;
    }
    .broadcastHeader {
        background-color: $tabActiveColor;
        color: $white;
    }
     
    .occurenceHeader {
        background-color: $white;
        color: $tabActiveColor;
    }
     
    .modal-body {
        padding-bottom: 3rem;
        overflow: auto !important;
        word-break: break-word !important;
    }
    .ButtonAck:hover {
        box-shadow: 5px 5px 10px $grey;
    }
     
    .modal-content {
        width: 500px;
        height: 350px;
    }
     
    .modalscroll {
        overflow-y: auto;
    }
     
    #Modal_Header {
        padding : 0 !important;
    }
    .modal-dialog {
        position: absolute !important;
        top: 45% !important;
        left: 50% !important;
        transform: translate(-50%, -50%) !important;
      }
      .occurenceSpanTime {
          margin-right: 2rem;
          word-break: break-all;
      }
}