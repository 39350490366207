.dashboard-header {
    display: flex;
    // flex-direction: row;
}

.dashboard-formfields {
    margin-left: 10px;
}

.dashboard-raiting-container {
    display: flex;
    margin-top: 40px;
    // flex-direction: row;
}

.dashboard-raiting{
    display: flex;
    flex-direction: column;
}

.table-container {
    display: flex;
    flex-wrap: wrap;
    
}

.score-avg-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: end;
}

.table-text {
    text-align: center;
}

.dashboard-text {
    font-family: AmericanSans-Regular;
}

.first-column{
    text-align: left !important;
    margin: 0;
    padding: 0 !important;
}

.no-data-text{
    color : red;
    font-family: "AmericanSans-Bold";
}

.not-enough-metrics {
   color: #FF8038;
   font-family: "AmericanSans-Bold";
   margin-bottom: 0 !important;
}
