
// .reset-space{
//   margin-right: 20px;
// }
.fieldLabelText {
  font-family: $font-family-AmericanSans-Medium;
  color: $tabActiveColor;
  font-size: 1.125rem;
  margin-bottom: 1rem;
}

.fieldLabelTextDeactivated {
  font-family: $font-family-AmericanSans-Medium;
  color: $tabDeactivatedColor;
  font-size: 1.125rem;
  margin-bottom: 1rem;
}

.fieldOptionText {
  font-family: $font-family-AmericanSans-Regular;
  color: $NavbarTextColor;
  font-size: 1rem;
  height: 3rem;
}
.form-control {
  border: 1px solid $VerticalLine;
}

.buttonSearch {
  font-family: $font-family-AmericanSans-Regular;
  color: $white;
  font-size: 1rem;
}
.submitColor {
  background-color:$tabActiveColor;
  border-color: $tabActiveColor;
}
.resetColor {
  background-color:$resetButton;
  border-color: $resetButton;
}

.resetColor:hover {
  background-color: $resetButton;
  border-color:$resetButton;
  box-shadow: 2px 2px 5px $grey;
}

.clearColor {
  background-color: #f53939;
  border-color: #f53939;
}

.clearColor:hover {
  box-shadow: 2px 2px 5px $grey;
  background-color: #f53939;
  border-color: #f53939;
}

.clearColor:disabled {
  box-shadow: none;
  background-color: #f53939;
  border-color: #f53939;
  cursor: not-allowed;
}

.submitColor:hover {
  background-color: $tabActiveColor;
  border-color:$tabActiveColor;
  box-shadow: 2px 2px 5px $grey;
}

.multi-select {
  --rmsc-main: #4285f4;
  --rmsc-hover: #f1f3f5;
  --rmsc-selected: #e2e6ea;
  --rmsc-border: #ccc;
  --rmsc-gray: #aaa;
  --rmsc-bg: #fff;
  --rmsc-p: 0.5rem; /* Spacing */
  --rmsc-radius: 0.3rem !important; /* Radius */
  --rmsc-h: 46px !important; /* Height */
  font-family: $font-family-AmericanSans-Regular;
  color: $NavbarTextColor !important;
  font-size: 1rem;
  // width: 150px;
  // word-wrap: break-word;
}
.selectedListContainer {
    margin-top: 1rem;
    width: 270px;
    height: 55px;
    overflow-y: auto;
}
.fixedHeightOverflow {
  height: 170px;
  overflow-y: scroll;
  border-bottom: 1px solid #b9b8b8;
}
.selectedList {
  border-radius: 1rem;
  background-color: $searchSelected;
  color: $white;
  // padding: 3px 35px 3px 10px;
  // width: 4.0625rem;
  height: 1.3125rem;
  font-size: 0.75rem;
  font-family: $font-family-AmericanSans-Regular;
  margin-right: 0.625rem;
  margin-bottom: 0.625rem;
  padding-left: 0.375rem;
  padding-top: 0.125rem;
}
.closeSearch{
  margin-left: 0.625rem;
  padding-right: 0.3125rem;
}
.clear-selected-button {
  visibility: hidden;
}
.dropdown-heading-value {
  visibility: hidden;
}
.gateSelectCss {
  color: $NavbarTextColor;
  font-size: 1rem;
  font-family: $font-family-AmericanSans-Regular;
  --rmsc-radius: 0.3rem !important;
  --rmsc-h: 46px !important;
  font-family: "AmericanSans-Regular";
  color: #36495A !important;
  font-size: 1rem;
}
.dropdown-heading-dropdown-arrow {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 2px 30px 2px 2px;
  border: none;
  background: transparent url('../../assets/images/Search_button.svg') no-repeat 95% 50%;
}
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 2px 30px 2px 2px;
  border: none;
  background: transparent url('../../assets/images/Dropdown_Icon.svg') no-repeat 95% 50%;
}
// .selectedListWork {
//   border-radius: 1rem;
//   background-color: #6DA1DD;
//   color: #ffffff;
//   font-size: 0.75rem;
//   font-family: "AmericanSans-Regular";
//   margin-right: 0.625rem;
//   margin-bottom: 0.625rem;
//   padding-left: 0.375rem;
//   padding-top: 0.125rem;
//   height:21px;
// }
.closeSearchWork {
  margin-left: 0.625rem;
  padding-right: 5px;
  margin-bottom: 2px;
}
.go626121005 input {
vertical-align: top !important;
margin-top: 5px !important; 
}
.go626121005 span {
vertical-align: top !important;
width: 168px !important;
padding-left: 10px !important;
}

.form-group.required .fieldLabelText:after {
content:"*";
color:red;
}

.spacingAround {
margin-top: 5px;
margin-bottom: 8px;
}
.rdt_Pagination {
position: absolute !important;
top: 0% !important; // 1%
right: 1% !important;
background-color: transparent !important;
border: none !important;
width: 32% !important;
color: $NavbarTextColor !important;
font-size: 1rem !important;
font-family: $font-family-AmericanSans-Regular !important;
}

// .rdt_Pagination {
//   position: fixed !important;
//   top: 60% !important;
//   right: 22% !important;
//   background-color: transparent !important;
//   border: none !important;
//   width: 25% !important;
// }

.sc-kLokih {
display: none !important;
}

.sc-cxNIbT {
display: none !important;
}
#pagination-first-page {
display: none !important;
}
#pagination-last-page {
display: none !important;
}

#tableSearch {
padding-bottom: 0.8rem !important;
padding-top: 0.8rem !important;
padding-left: 3.5rem !important;
padding-right: 17rem !important;
font-size: 1rem !important;
border-radius: 2rem !important;
font-family: $font-family-AmericanSans-Regular !important;
}

#tableSearch::placeholder {
color: $tabActiveColor;
opacity: 0.6;
}

.tablesearchIcon {
top: 11px;
left: 36px;
}

.tablesearchCloseIcon {
position: absolute;
top: 16px;
right: 34px;
z-index: 3;
width: 15px;
height: 15px;
 background-color: #444444;
}
.sc-iemXMA{
padding: 0px 0px 0px 0px !important;
background-color: transparent !important;
position: absolute !important;
top: 0% !important;
}

.rdt_TableHeader {
display: none !important;
}

// .tableRowLine {
//   border-top: 1px solid #c4c3c3;
// }

.sc-hHEjAm {
position: relative;
top: 25px !important;
margin-bottom: 32px;
}

.CrewInfoTable .sc-hHEjAm {
position: relative;
top: 0px !important;
margin-bottom: 32px;
}

.timeText {
margin-top: 3px;
margin-right: 20px;
color: $lastUpdated !important;
font-size: 1rem !important;
font-family: $font-family-AmericanSans-Regular !important;
}

.showingText {
margin-top: -12px;
margin-right: 191px;
color: $lastUpdated !important;
font-size: 1rem !important;
font-family: $font-family-AmericanSans-Regular !important;
}

@media only screen and (max-width: 768px) {
.go626121005 span {
  vertical-align: top !important;
  width: 115px !important;
  padding-left: 10px !important;
}

#tableSearch {
  padding-right: 10rem !important;
}
}

.sc-fujyUd {
position: absolute !important;
left: 95% !important;
z-index: 33333333 !important;
}
@media only screen and (max-width: 1024px) {
  .sc-fujyUd {
    position: absolute !important;
    left: 96% !important;
    z-index: 33333333 !important;
  }
}

.sc-fKgIGh {
display: none !important;
}

.sc-iCoHVE {
padding-top: 30px !important;
padding-left: 10px !important;
}

.sc-iCoHVE:hover:not(:disabled) {
cursor: pointer;
background-color: transparent !important;
}
.sc-bYwzba {
margin: 0px 0px !important;
}
.ShiftDataTable {
  .okIIh {
    justify-content: left !important;
    padding-left: 0px !important;
    padding-right: 15px !important;

  }
}

.ShiftDataTable {
  .jOZyoc {
    //justify-content: left !important;
  }
}

@media all and (max-width: 780px) {
  .gcXkZv {
    justify-content: left !important;
    padding-left: 0px !important;
    padding-right: 15px !important;
  }
}

.ShiftDataTable {
  .gtCrbz {
    padding-left: 1px !important;
  }
}

.has-search .form-control {
padding-left: 4rem !important;
}

.has-search .form-control-feedback {
position: absolute;
z-index: 2;
display: block;
width: 1.5rem;
height: 1.5rem;
line-height: 2.375rem;
text-align: center;
pointer-events: none;
color: #aaa;
}
.form-control-search{
  padding: 0.6rem 6.75rem  !important;
  border-radius: 1.5rem  !important;
}

.sc-lmgQde {
min-height: 85px !important;
}

.emptyGates {
font-size: 10px;
color: red;
padding-top: 5px;
}

.sc-iCoHVE:focus {
outline: none !important;
background-color: transparent !important; 
}

.sc-iCoHVE:disabled {
background: rgba(255,255,255,0.8);
filter: blur(6px)
}

.sc-jSFkmK div:first-child  {
  white-space: initial !important;
  // overflow: auto
}

.rdt_TableHeadRow {
  background-color:$TableHeaderBg !important;
  color: $white !important;
}

.emptySearchText {
  font-family: $font-family-AmericanSans-Regular;
  font-size: 1.125rem;
}

@media (min-width: 1025px) and (max-width: 1199px) {
  .sc-fujyUd {
    position: absolute !important;
    left: 95% !important;
    z-index: 33333333 !important;
  }
}