$colorText: #f5f5f5;
body {
  background-color: #2e3532;
  color: $colorText;
}

#root {
  background-color: #2e3532;
  color: $colorText;
}
.App {
  background-color: #2e3532;
  color: $colorText;
}
.navbar {
  border-bottom: 1px solid #c7c7c7;
  color: #cdcdcd;
}

.tabContainer a {
  color: #cdcdcd;
}

.hamburgerMenu {
  background-color: #262c29;
  color: $colorText;
}

/*about section theme*/
.modal-content {
  background-color: #262c29;
  color: $colorText;
}

/*feedback section*/
.textBox {
  background: #262c29;
  color: $colorText;
}

// Shift Information

/*head*/
.UqiCh {
  color: $colorText;
}

.jUmmd {
  background: #39413d !important;
  color: $colorText;
}
.BvSdT {
  background: #39413d !important;
  color: $colorText;
}
.iNMbZG {
  color: $colorText;
}

.MuiPaper-root {
  background: #39413d !important;
  color: $colorText;
}

table.FlightInfoTable tbody tr td {
  color: $colorText;
}

table .table {
  background-color: transparent;
  color: $colorText;
}

.lbIaJf {
  background: #39413d !important;
  color: $colorText;
}

.eyxMQk {
  background: #39413d !important;
  color: $colorText;
}

.gbEGzq {
  background-color: transparent;
  color: $colorText;
}

table.CrewInfoTable tbody tr td {
  background-color: transparent;
  color: $colorText;
}

.eLjkxL {
  background-color: transparent;
  color: $colorText;
}
.OvPLv {
  color: $colorText;
}

.khKjDK {
  background: #39413d !important;
  color: $colorText;
}

.cvahse {
  background: #39413d !important;
  color: $colorText;
}

.no-task-info {
  color: $colorText;
}

.heading {
  color: #1b62c0;
}

li {
  color: #1b62c0;
}
#about .titleHead {
  color: $colorText;
}
#about .titleBody {
  color: $colorText;
}
.textAbove {
  color: $colorText;
}
.smallText {
  color: $colorText;
}

.notificationHeader {
  color: $colorText;
}
.TaskToggleButton {
  color: $colorText;
}

.ctbByX {
  background: #39413d !important;
  color: $colorText;
}
.fgNHuc {
  background: #39413d !important;
  color: $colorText;
}
.hMTevG {
  background: #39413d !important;
  color: $colorText;
}

.hbEnCs {
  background: #39413d !important;
  color: $colorText;
}
.PkXhu {
  background: #39413d !important;
  color: $colorText;
}
.olhdY {
  background: #39413d !important;
  color: $colorText;
}
.dxnAzg {
  background: #39413d !important;
  color: $colorText;
}
.guPRhK {
  background: #39413d !important;
  color: $colorText;
}
.ignUrH {
  color: $colorText;
}

.metrics-container {
  p {
    color: white !important;
  }
}

.shiftHeader {
  p {
    color: #c0d4e2 !important;
  }
}

.not-available-message {
  background-color: #2e3532;
  p {
    color: #c0d4e2;
  }
}

.menu-container {
  p {
    color: #c0d4e2;
  }
}

.selected-title {
  p {
    color: #0078d2 !important;
  }
}

.metric-card {
  background-color: #1c2a34;
  p {
    color: #c0d4e2 !important;
  }
}

.metric-card:hover {
  box-shadow: 0px 0px 10px #c0d4e2;
}

.not-available-display {
  box-shadow: none !important;
}

.metric-details {
  background-color: #1c2a34;
  border-color: #405b6f !important;
  p {
    color: #c0d4e2 !important;
  }
}

.tips-container {
  h1 {
    color: #c0d4e2 !important;
  }
  li {
    color: #c0d4e2 !important;
  }
}

.card-container {
  background-color: #1c2a34;
  border-color: #405b6f;
  p {
    color: #c0d4e2;
  }
}

.tooltip-header {
  background-color: #1c2a34 !important;
}

.tooltip-body {
  background-color: #1c2a34 !important;
}

.average-scores-container {
  background-color: #1c2a34 !important;
  border-color: #405b6f;
}

.average-scores-text {
  color: #c0d4e2;
}

.average-scores-subtext {
  color: #c0d4e2;
}

.star-rating-container {
  background-color: #1c2a34 !important;
  border-color: #405b6f;
}

.star-rating-text {
  color: #c0d4e2;
}

.tiles-container {
  background-color: #1c2a34 !important;
  border-color: #405b6f;
  color: #c0d4e2;
}

.departure-text {
  color: #c0d4e2;
}

.secondary-metrics-text {
  color: lightgray;
}

.secondary-metrics-border {
  border-bottom: 1px solid lightgray;
}
