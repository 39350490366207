#NotificationModal {
    position: absolute !important;
    top: 40% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    .modal-header {
        font-size: 1.5rem;
        font-family: $font-family-AmericanSans-Bold;
    }
    .modal-header .close{
        font-size: 2rem;
    }
    .broadcastHeader {
        background-color: $tabActiveColor;
        color: $white;
    }
     
    .occurenceHeader {
        background-color: $white;
        color: $tabActiveColor;
    }
     
    .modal-body {
        padding-bottom: 3rem;
        overflow: auto !important;
        word-break: break-word !important;
    }
    .ButtonAck:hover {
        box-shadow: 5px 5px 10px $grey;
    }
     
    .modal-content {
        width: 500px;
        height: 350px;
    }
     
    .modalscroll {
        overflow-y: auto;
    }
    .modalText {
        font-size: 1rem;
        font-family: $font-family-AmericanSans-Bold;
        color: $NavbarTextColor;
        margin-bottom: 2px;
    }
    .marginAddDelete {
        margin-top: 0.5rem;
    }
     
    #Modal_Header {
        padding : 0 !important;
    }
}