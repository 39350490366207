@import "../../assets/sass/typography";

.login-body {
  display: flex;
  align-items: center;
  justify-content: center;
}

.mainBody {
  width: 450px;
  height: 531px;
  border-radius: 20px;
  justify-content: center;
  color: #ffffff;
  padding: 40px;
  text-align: center;
}

// .mainBody ::shadow{
//   box-shadow: #0000000;
// }

.loginTitle {
  margin-top: 40px;
  font-family: AmericanSans-Bold;
  font-size: 30px;
  color: #1b62c0;
  padding-bottom: 20px;
}

.wrapperData {
  background: #ffffff;
  padding: 0px 0px 40px 0px;
  font-family: AmericanSans-Bold;
  .input-data {
    height: 60px;
    width: 100%;
    position: relative;
    .text-input {
      height: 100%;
      width: 100%;
      border: none;
      font-size: 18px;
      color: #36495a;
      border-bottom: 1px solid #d8d8d8;
      outline: none;
      font-family: AmericanSans-Regular;
    }
    .text-label {
      position: absolute;
      bottom: 10px;
      top: 15px;
      left: 0;
      color: #1761a7;
      pointer-events: none;
      transition: all 0.3s ease;
      font-size: 18px;
    }
  }
}

.input-data {
  .text-input {
    &:focus {
      ~ {
        .text-label {
          transform: translateY(-40px);
          font-size: 18px;
        }
        .underline {
          &:before {
            transform: scaleX(1);
          }
        }
      }
    }
    &:valid {
      ~ {
        .text-label {
          transform: translateY(-40px);
          font-size: 18px;
        }
        .underline {
          &:before {
            transform: scaleX(1);
          }
        }
      }
    }
  }
  .underline {
    position: absolute;
    width: 100%;
    // height: 2px;
    // bottom: 0;
    // color: #d8d8d8;
    &:before {
      position: absolute;
      content: "";
      height: 100%;
      width: 100%;
      transform: scaleX(0);
      transform-origin: center;
      transition: transform 0.3s ease;
    }
  }
}

.codeHead {
  color: #4158d0;
  font-family: AmericanSans-Bold;
  font-size: 18px;
}

.buttonLabelGroup {
  float: left;
}

.buttonLabel {
  color: #1e4b82;
  border: 2px solid #d8d8d8;
  border-radius: 44%;
  background-color: #ffffff;
  width: 65px;
}

.buttonLabel.active {
  color: #ffffff;
  background-color: #1e4b82;
}

.buttonLabel.inactive {
  color:#1e4b82;
  background-color:  #ffffff;
}

.actionButton {
  margin-top: 70px;
  // margin-bottom: 40px;
  justify-content: center;
  .button1 {
    width: 80px;
    height: 30px;
    font-size: smaller;
    background-color: #ff711b;
    color: white;
    border: none;
  }
  .button2 {
    width: 80px;
    height: 30px;
    font-size: smaller;
    background-color: #1b61c0;
    color: white;
    border: none;
  }
}
