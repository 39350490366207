$backgroundColor: #314d7b;
$black: #000000;
$white: #ffffff;
$grey: #495057;
$blue: #1c56f1;
$navbarBottomBorder: #c7c7c7;
$VerticalLine: #D8D8D8;
$NavbarTextColor: #36495A;
$Code113TextColor: #ff1e00;
$lightGrey: #f7f7f7;
$tabActiveColor: #1B62C0;
$tabBottomBorderColor: #D8D8D8;
$TableHeaderBg:#1E4B82;
$shadow: #7d7d7d;
$lastUpdated: #676767;
$resetButton: #ff711a;
$searchSelected: #6DA1DD;

$tabDeactivatedColor: #b7b7b7;

:export {
    white: $white;
    verticalLine: $VerticalLine;
    navbarTextColor: $NavbarTextColor;
    tableHeaderBg: $TableHeaderBg;
}