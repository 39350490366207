@import './assets/sass/main.scss';
@import './app/App.scss';
@import './common/components/navigation/HeaderNavbar.scss';
@import './common/components/tour/TourModescreen.scss';
@import './modules/shift/ShiftComponent.scss';
@import './modules/details/DetailsComponent.scss';
@import './modules/search/SearchComponent.scss';
@import './modules/notification/NotificationComponent.scss';
@import './common/components/modal/VerticalCenteredModal.scss';
@import './common/components/modal/NotificationModal.scss';
@import './modules/login/LoginComponent.scss';
@import './common/components/spinner/Spinner.scss';
@import './modules/scorecardDashboard/SCDashboardComponent.scss';

@font-face {
  font-family: $font-family-AmericanSans-Medium;
  src: url(./assets/fonts/AmericanSans-Medium.otf) format('OpenType');
}
@font-face {
  font-family: $font-family-AmericanSans-Regular;
  src: url(./assets/fonts/AmericanSans-Regular.otf) format('OpenType');
}
@font-face {
  font-family: $font-family-AmericanSans-Bold;
  src: url(./assets/fonts/AmericanSans-Bold.otf) format('OpenType');
}

html{
  height: 100%;
}
body {
  height: 100%;
  margin: 0;
  // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  //   'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  //   sans-serif;
  // font-family: $font-family-AmericanSans-Medium;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#root{
  height: 100%;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.dark{
  @import '../src/theme/dark.scss';
}

.light{
  @import '../src/theme/light.scss';
}