.App-header {
  background-color: $backgroundColor;
  min-height: 100vh;
  color: $white;
}

.headerLogo {
  height: 5rem;
  width: 5rem;
  margin-left: 3rem;
  margin-top: 1rem;
}

.tabContainer {
  .nav-tabs {
    border-bottom: 1px solid $tabBottomBorderColor;
  }
  .nav-tabs .nav-link.active {
    color: $tabActiveColor;
    background-color: $lightGrey;
    border-color: $white $white $blue;
    border-width: 0 0 2px 0;
    font-family: $font-family-AmericanSans-Medium;
    font-size: 1.12rem;
  }
  .nav-link:visited {
    font-family: $font-family-AmericanSans-Regular;
    font-size: 1.12rem;
  }
  a {
    color: #9DA6AB; 
  }
}

.modal-dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
}

#overlay {
  position: fixed; /* Sit on top of the page content */
  display: block; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($black,0.5); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
}


