.error {
	color :#740000; //#c30019;
	position: absolute;
}

.error-container {
	width: 55%;
    height: 25%;
    padding: 30px;
    position: fixed;
	top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    // background:#f2dcdf;
    box-shadow: 1px 1px 10px rgba(0,0,0,0.5);
	border-radius: 10px;
	color : #c30019;
	font-size: 22px;
	display: inline-block;
	z-index: 6;

	h4 {
		font-size: 26px;
		font-weight : bold;
	}

	button {
		position: absolute;
		right: 30px;
		top: 30px;
		padding: 0;
		border: 0 none;
		background: none;
		color: #740000;

		cursor : pointer;
	}
}

.Error1100 {
	background-color:#5aadd1;
}
.Error1020 {
	background-color:#C6DC32;
}
.Error1500 {
	background-color:#FEFBCE;
}
.Error1 {
	background-color:#ed7264;
}
.Error1013 {
	background-color:#FEFBCE;
}
.Error1021 {
	background-color: #C4BBD8;
}
.Success200 {
	background-color: #aeeb92;
}
.ErrorCancelled {
	width: 100%;
	bottom: 5px;
	opacity: 1;
	top:auto;
	animation-name: errorMessage;
  	animation-duration: 2s; 
	background-color:#c30019;
	color: white !important;
}
@keyframes errorMessage {
	from {bottom: -137px;}
    to {bottom: 5px;}
}
.defaultError {
	background-color: #EFCFDE;
}