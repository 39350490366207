body{
    background-color: white;
    color:black;
}

#root{
    background-color: white;
    color:black;
}

.fieldLabelText {    
    color: #1B62C0;
}

.form-control {
    border: 1px solid #D8D8D8;
}

.fieldOptionText {    
    color: #36495A;    
}
.submitColor {
    background-color: #1B62C0;
    border-color: #1B62C0;
}

.resetColor {
    background-color: #ff711a;
    border-color: #ff711a;
}
.clearColor {
    background-color: #f53939;
    border-color: #f53939;
}
.no-task-info {
    color: #36495A;
}

.tabContainer a {
    color: #9DA6AB;
}

.navbar {
    border-bottom: 1px solid #c7c7c7;
    color: #36495A;
}


