.notificationHeader {
    font-family: $font-family-AmericanSans-Medium;
    font-size: 1.25rem;
    color: $NavbarTextColor;
}

.notificationHeaderCode113 {
    font-family: $font-family-AmericanSans-Medium;
    font-size: 1.25rem;
    color: $Code113TextColor;
    font-weight: bolder;
}

.notificationLine {
    border-top: 2px solid #D8D8D8;
    font-family: $font-family-AmericanSans-Regular;
}
.notificationTimeStamp {
    font-family: $font-family-AmericanSans-Regular;
    font-size: 1rem;
    color: $searchSelected;
}

.EmptyNotification {
    padding-top: 2rem;
    font-size: 1rem;
}

.notificationText {
    word-break: break-word;
}

.notificationTextCode113 {
    word-break: break-word;
    color: $shadow;
    font-weight: bolder;
}