.headerVerticalLine{
    border-left: 2px solid $VerticalLine;
    height: auto;
    margin-top: 22px;
}
.dropdownCss {
    margin-top: 4.5%;
    padding-left: 0.5rem;
}

.headerProfileTextContainer {
    display: flex;
    flex-direction: column;
    padding-right: 1rem;
    @media screen and (max-width : 800px) {
        padding-right: 0.25rem;
    }
}
.headerProfileText {
    margin-top: 25px;
    align-self: center;
    

    &.stationCode {
        padding-left: 1rem;
        padding-right: 1rem;
        @media screen and (max-width : 800px) {
            padding-left: 0.25rem;
            padding-right: 0.25rem;
        }
        align-self: center;
    }
}
.headerAdminText{
    margin-top: 25px;
    padding-right: 1rem;
    padding-left: 1rem;
    @media screen and (max-width : 800px) {
        padding-left: 0.25rem;
        padding-right: 0.25rem;
    }
}
.headerProfileIcon {
    margin-top: 1.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    align-self: center;

    @media screen and (max-width : 800px) {
        padding-left: 0.25rem;
        padding-right: 0.25rem;
    }
}
.CalendarIcon, .hamburgerIcon{
    // padding-right: 1rem;
    // margin-top: 1.5rem;
    // width: 35px;
    // height: 35px;
    // align-self: center;

    cursor: pointer;
    @media screen and (max-width : 800px) {
        padding-right: 0.25rem;
    }
}
.BellIcon {
    // margin-top: 1.2rem;
    // margin-right: 1.2rem;
    width: 35px;
    height: 35px;
    // align-self: center;
    @media screen and (max-width : 800px) {
        padding-right: 0.25rem;
    }
}

.navbar-collapse {

    position : relative;

}

.hamburgerIcon{
    margin-left : 0.5rem;
}

.hamburgerMenu {
    width : 300px;
    border-radius: 5px;
    padding : 2rem 0 2rem 0;
    border: 1px solid $VerticalLine;
    background :$white;
    box-shadow: 1px 1px 5px rgba(0,0,0,0.5);
    top: 20px;

    position: absolute;

    z-index : 4;

    right : 0;

    ul {

        margin : 0;

        padding : 0;

        li {

            list-style: none;

            padding : 1.2rem 2rem 1.2rem 1.5rem;

            cursor: pointer;

            font-size: 1.12rem;

            line-height: normal;

            &:hover {

                background: $lightGrey;

                color : $backgroundColor;

                font-family: "AmericanSans-Medium";

            }
    
        }
        img {
            margin: 10px;
        }

    }
    
}

.subMenu {
    padding-left: 4.8rem !important;
    font-size: 1.05rem !important;
}

.learnMenu {
    padding-left: 5.8rem !important;
    display: flex;
    li {
        font-size: 1rem !important;
        padding-left: 5px !important;
    }
}

.searchBlur {
    position: fixed;
    background-color: rgba(0,0,0,0.5);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
    transition: opacity 0.2s linear;
    visibility: hidden;

    &.active {
        opacity: 1;
        visibility: visible;
    }
}

.inactive-task-tab .blur-component-overly.active {
    display: none;
}

.searchBlur.active + .blur-component-overly.active {

    display : none;
}



.ThemeIcon{
    
    padding-right: 1rem;
    margin-top: 1.5rem;
    align-self: center;

    cursor: pointer;
    @media screen and (max-width : 800px) {
        padding-right: 0.25rem;
    }
}

.ThemeIcon > img{
    width:1.5rem;
}

.error {
	color :#c30019;
	position: absolute;
}

.error-container {
	width: 55%;
    height: 20%;
    padding: 30px;
    position: fixed;
	top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    background:#f2dcdf;
    box-shadow: 1px 1px 10px rgba(0,0,0,0.5);
	border-radius: 10px;
	color : #c30019;
	font-size: 22px;
	display: inline-block;

	h4 {
		font-size: 26px;
		font-weight : bold;
	}

	button {
		position: absolute;
		right: 30px;
		top: 30px;
		padding: 0;
		border: 0 none;
		background: none;

		cursor : pointer;
	}
}

.notification-badge {
    height: 15px;
    width: 15px;
    background-color: #008001;
    border-radius: 50%;
    position: absolute;
    margin-top: 20px;
    margin-left: 20px;
    
}

#modal-style .modal-content {
        width: auto;
        background: border-box;
        border: 0;
        align-self:baseline;
}
