.shiftHeader {
    color: $tabActiveColor;
    font-size: 1.625rem;
    font-family: $font-family-AmericanSans-Bold;
}
// .backArrow {
//     width: 1rem;
//     height: 1.5rem;
// }

