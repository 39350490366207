.tourTitle {
    height: auto;
    width: 100%;
    background-color: #1C56F1;
    display: flex;
    justify-content: space-between;
    margin-left: 5px;
    margin-right: 5px;
    p, span {
        font-family: AmericanSans-Regular;
        color:white;
        font-size: 2rem;
        margin: 10px;
    }
    span {
        cursor: pointer
    }
}
.navContainer {
    border-bottom: 1px solid #c7c7c7;
    font-family: AmericanSans-Regular;
    color: #36495A;
    margin-bottom: 1.5rem !important;
    background-color: transparent !important;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1rem;
    width: -webkit-fill-available;
}

.tdTour {
    vertical-align: middle !important;

}

.titleTaskTour {
    vertical-align: middle;
    color: #1C56F1;
    margin-top: 10px;
    padding-bottom: 25px;
    border-bottom: 1px solid #c7c7c7;
    width: 100%
}
 .tourHeader {
     th {
         width: 16%
     }
 }

@media only screen and (min-width:768px) {
    .navContainer {
        flex-flow: row nowrap !important;
        justify-content: flex-start !important;
    }
    .tdTour {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }
}

.expandTour {
    background: 0 !important;
    border: 0 !important;
    position: relative;
    z-index: 5

}
.tourContainer {
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.headerTourHeader {
    display: flex;
    justify-content: space-between;
    padding: 10px 5px 10px 5px;
    margin-left: auto;
    margin-right: auto;
    border-bottom: 1px solid #c7c7c7;
    font-family: AmericanSans-Regular;
    color: #36495A;
    align-self: normal;
    width: 80%;

}
.navbarTourIcons {
    display:flex;
    justify-content: space-around;
    justify-self: end;
    align-self: center;
}
.calendarIconTour{
    margin-top: 1.4rem;
    margin-right: 1.2rem;
    width: 25px;
    height: 25px;
    @media screen and (max-width : 800px) {
        padding-right: 0.25rem;
    }
}

.stepTourButtons{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 50px 20px 50px
}

.buttonTour {
    margin-right: 0 !important;
    width: 150px
}

.stepBody {
    margin-left: 25px;
    margin-right: 25px;
    word-spacing:10px;
    line-height: 30px;
}

.message {
    align-self:baseline; 
    margin-top: 3rem;
    color: #36495a;
}

.stepBody2{
    font-size: 20;
        color: #36495A;
        font-family: "AmericanSans-Bold";
       
        line-height: 30px;
        text-align: justify;
}

.toggleButtonTour {
    vertical-align: middle !important;
}