@import "../../assets/sass/color";
@import "../../assets/sass/typography";

.header1 {
  font-size: 1.25rem;
  font-family: "AmericanSans-Medium";
  color: #1b62c0;
  font-weight: bold;
  padding: 15px 0 0 1.5vw;
}

.header2 {
  font-family: "AmericanSans-Medium";
  font-size: 1.25rem;
  font-weight: medium;
  color: #1b62c0;
  padding: 0 0 15px 1.5vw;
}

.care {
  width: 30%;
  float: left;
  padding: 0 1.5vw 0 1.5vw;
}

.operation {
  width: 36%;
  float: left;
  padding: 0 1.5vw 0 1.5vw;
  border-left: 1px solid #f1f1f1;
  border-right: 1px solid #f1f1f1;
}

.comment {
  width: 30%;
  float: left;
  padding: 5px 0px 25px 21px;
}

.commentsection {
  padding: 15px 20px 15px 20px;
  border: 1px solid #d8d8d8;
  border-radius: 10px;
  height: 8rem;
}

.commentheader {
  font-size: 16px;
}

.commentbody {
  font-size: 14px;
  font-style: italic;
  color: #1b62c0;
  padding-top: 30px;
}

.commentbody span {
  border-bottom: 1px solid #6da1dd;
  padding-bottom: 5px;
  line-height: 35px;
}

table.CrewInfoTable tbody tr td {
  padding: 15px 10px 0 10px !important;
  position: relative;
  font-size: 16px;
  color: #36495a;
  vertical-align: top;
  width: 30%;
}

table.CrewInfoTable tbody tr td:last-child {
  padding-right: 0px;
}

.rolesBtn {
  font-family: $font-family-AmericanSans-Regular;
  color: $white;
  font-size: 1rem;
}
.rolesBtnSubmit {
  background-color: $tabActiveColor;
  border-color: $tabActiveColor;
}

.rolesBtnSubmit:hover {
  background-color: $tabActiveColor;
  border-color: $tabActiveColor;
  box-shadow: 2px 2px 5px $grey;
}

// ipad styles

@media screen and (max-width: 1100px) {
  .care {
    width: 100%;
    margin-bottom: 30px;
  }
  .operation,
  .comment {
    width: 50%;
  }
}
