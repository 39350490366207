@import "../../assets/sass/_color";
@import "../../assets/sass/typography";

.popupRolesContainer {
  max-height: 320px;
  min-width: 335px;
  max-width: 380px;
  overflow-x: hidden;
  overflow-y: auto;
  color: #36495a;
}

.aboutHead {
  color: #1b62c0;
  font-size: 24px;
  font-family: AmericanSans-Bold;
  padding: 10px 3px 0px 21px;
}

.eachRoleContainer {
  width: 100%;
  margin: 2px;
  padding: 5px 10px 5px 1px;
  border-bottom: #e9ecef 1px solid;
  min-height: 60px;
}

.eachRoleLine1 {
  width: 100%;
  max-height: 60px;
  min-height: 40px;
  display: block;
}

.eachRoleLine2 {
  width: 100%;
  max-height: 80px;
  min-height: 40px;
  margin: 4px 1px 3px 2px;
}

.eachRoleUserImage {
  float: left;
  margin-left: 10px;
  margin-right: 5px;
  max-height: 100%;
  display: inline-block;
  margin: 7px 5px 0px 18px;
}

.eachRoleUserName {
  float: left;
  max-width: 200px;
  font-size: 14px;
  max-height: 100%;
  overflow: auto;
  display: inline-block;
  margin: 10px 0px 0px 7px;
}

.eachRoleDisplaySpace {
  width: 40px;
  float: right;
  margin-right: 10px;
}

.roleSelectedText {
  max-width: 15px;
  font-size: 16px;
  float: left;
  margin: 9px 3px 0px 1px;
  cursor: pointer;
}

.roleIcon {
  max-width: 9px;
  float: right;
  margin: 8px 10px 0px 2px;
  cursor: pointer;
}

.roles-popup-button-section {
  width: 100%;
  padding: 15px 2px 6px 5px;
}

.roles-cancel {
  float: left;
  background-color: $resetButton;
  border-color: $resetButton;
  font-family: "AmericanSans-Regular";
  color: #ffffff;
  font-size: 1rem;
  margin-right: 1.5rem !important;
  margin-bottom: 0.5rem !important;
  width: 68px !important;
  font-size: 13px;
  cursor: pointer;
  text-align: center;
}

.roles-cancel:hover {
  background-color: $resetButton;
  border-color: $resetButton;
  box-shadow: 2px 2px 5px $grey;
}

.roles-save {
  float: right;
  background-color: $tabActiveColor;
  border-color: $tabActiveColor;
  font-family: "AmericanSans-Regular";
  color: #ffffff;
  font-size: 1rem;
  margin-right: 0.3rem !important;
  margin-bottom: 0.5rem !important;
  width: 68px !important;
  cursor: pointer;
  font-size: 13px;
  text-align: center;
}

.roles-save:hover {
  background-color: $tabActiveColor;
  border-color: $tabActiveColor;
  box-shadow: 2px 2px 5px $grey;
}

.setRolesErrContainer {
  display: block;
  width: 100%;
  overflow: auto;
  color: chocolate;
  font-size: 12px;
  max-width: 270px;
}

.setRolesErr:before {
  content: "\a";
  white-space: pre;
}

.setRolesErr {
  display: block;
  max-width: 100%;
  overflow: auto;
}

.setMergeRoles {
  float: right;
  max-width: 200px;
  font-size: 14px;
  max-height: 100%;
  overflow: auto;
  display: inline-block;
  margin: 10px 10px 0px 7px;
  align-self:flex-end;
}
